// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_rj d_gv d_cs";
export var heroHeaderCenter = "s_gw d_gw d_cs d_dw";
export var heroHeaderRight = "s_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "s_rk d_gr d_cw";
export var heroParagraphCenter = "s_gs d_gs d_cw d_dw";
export var heroParagraphRight = "s_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "s_rl d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "s_rm d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "s_rn d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "s_rp d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "s_rq d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "s_rr y_rr";
export var heroExceptionNormal = "s_rs y_rs";
export var heroExceptionLarge = "s_rt y_rt";
export var Title1Small = "s_rv y_rv y_r7 y_r8";
export var Title1Normal = "s_rw y_rw y_r7 y_r9";
export var Title1Large = "s_rx y_rx y_r7 y_sb";
export var BodySmall = "s_ry y_ry y_r7 y_sr";
export var BodyNormal = "s_rz y_rz y_r7 y_ss";
export var BodyLarge = "s_rB y_rB y_r7 y_st";