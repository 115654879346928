// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "v_rS d_gS d_cw d_dv";
export var quoteParagraphCenter = "v_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "v_gV d_gV d_cw d_dx";
export var quoteRowLeft = "v_rT d_bG";
export var quoteRowCenter = "v_rV d_bD";
export var quoteRowRight = "v_rW d_bH";
export var quoteWrapper = "v_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "v_gR d_gR";
export var quoteExceptionSmall = "v_rX y_rX";
export var quoteExceptionNormal = "v_rY y_rY";
export var quoteExceptionLarge = "v_rZ y_rZ";
export var quoteAuthorExceptionSmall = "v_r0 y_r0";
export var quoteAuthorExceptionNormal = "v_r1 y_r1";
export var quoteAuthorExceptionLarge = "v_r2 y_r2";