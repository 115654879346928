// extracted by mini-css-extract-plugin
export var alignLeft = "t_qh d_fp d_bG d_dv";
export var alignCenter = "t_bP d_fq d_bD d_dw";
export var alignRight = "t_qj d_fr d_bH d_dx";
export var alignColumnLeft = "t_q7 d_fs d_bN d_dv";
export var alignColumnCenter = "t_q8 d_ft d_bP d_dw";
export var alignColumnRight = "t_q9 d_fv d_bQ d_dx";
export var milestonesContainer = "t_rC d_dW";
export var milestonesContainerFull = "t_rD d_dT";
export var milestonesSubtitle = "t_mf d_mf d_cs";
export var milestonesComponentWrapper = "t_mb d_mb d_cv";
export var compContentWrapper = "t_rd d_mc d_bz d_bJ d_bM d_bF d_c7 d_H";
export var milestonesMainHeader = "t_l8 d_l8 d_w d_cr";
export var milestonesSubHeader = "t_l9 d_l9 d_w";
export var milestonesComponentWrapperRow = "t_rF d_H";
export var milestonesBlockWrapper = "t_rG";
export var milestonesTextBlockWrapper = "t_rH";
export var milestonesComponentHeader = "t_md d_md d_w d_cr";
export var milestonesComponentParagraph = "t_mj d_mj d_w";
export var btnWrapper = "t_d2 d_bz d_ck";
export var btnWrapperCards = "t_rg d_bz d_cd";
export var imageWrapper = "t_rJ d_cs d_bz";
export var Title1Small = "t_rv y_rv y_r7 y_r8";
export var Title1Normal = "t_rw y_rw y_r7 y_r9";
export var Title1Large = "t_rx y_rx y_r7 y_sb";
export var Title2Small = "t_rK y_rK y_r7 y_sc";
export var Title2Normal = "t_rL y_rL y_r7 y_sd";
export var Title2Large = "t_rM y_rM y_r7 y_sf";
export var SubtitleSmall = "t_qd y_qd y_r7 y_sk";
export var SubtitleNormal = "t_qf y_qf y_r7 y_sl";
export var SubtitleLarge = "t_qg y_qg y_r7 y_sm";
export var Subtitle2Small = "t_rN y_rN y_r7 y_sn";
export var Subtitle2Normal = "t_rP y_rP y_r7 y_sp";
export var Subtitle2Large = "t_rQ y_rQ y_r7 y_sq";
export var BodySmall = "t_ry y_ry y_r7 y_sr";
export var BodyNormal = "t_rz y_rz y_r7 y_ss";
export var BodyLarge = "t_rB y_rB y_r7 y_st";
export var exceptionLineHeight = "t_rR y_sB";